import React, { lazy } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import { getCrumb } from 'src/components/MainPage/_shared/Breadcrumbs/Breadcrumb';
import { roleSet } from 'src/constants/role';
import type { AppRoute } from '../types';
import { SuspenseContainer } from 'src/components/MainPage/_shared/page';
import { syncTabs } from 'src/pages/management/SyncPage/SyncTabs';

const AuditPromoCodesPage = lazy(
  () => import('src/pages/management/AuditPromoCodesPage')
);
const AuditPage = lazy(() => import('src/pages/management/AuditPage'));
const ShopsPageOLD = lazy(() => import('src/components/MainPage/ShopsPage'));
const ShopsPage = lazy(() => import('src/pages/management/ShopsPage'));
const ShopPage = lazy(() => import('src/pages/management/ShopPage'));
const StreetsPageOLD = lazy(
  () => import('src/components/MainPage/StreetsPage')
);
const MangoPage = lazy(() => import('src/pages/management/MangoPage'));
const SettingsPage = lazy(() => import('src/pages/management/SettingsPage'));
const EntrepreneursPage = lazy(
  () => import('src/pages/management/EntrepreneursPage')
);
const EntrepreneurPage = lazy(
  () => import('src/pages/management/EntrepreneurPage')
);
const SyncPage = lazy(() => import('src/pages/management/SyncPage'));
const ZonesPage = lazy(() => import('src/pages/management/ZonesPage'));

export const managementRoutes: AppRoute[] = [
  {
    element: <ProtectedRoute roles={roleSet.cashierAndOperatorToSuperAdmin} />,
    handle: {
      label: 'Управление',
      icon: <SettingsIcon />,
      roles: roleSet.cashierAndOperatorToSuperAdmin,
    },
    children: [
      {
        path: `audit-promocodes`,
        element: <AuditPromoCodesPage />,
        handle: {
          label: 'Журнал-промокоды',
          roles: roleSet.cashierAndOperatorToSuperAdmin,
        },
      },
      {
        element: <ProtectedRoute roles={roleSet.adminToSuperAdmin} />,
        children: [
          {
            path: `audit`,
            element: <AuditPage />,
            handle: {
              label: 'Журнал',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            element: <ProtectedRoute roles={roleSet.superAdmin} />,
            children: [
              {
                path: `v2/shops`,
                element: <ShopsPageOLD />,
                handle: {
                  label: 'Точки продаж',
                  roles: roleSet.superAdmin,
                },
              },
              {
                path: `shops`,
                handle: {
                  crumb: getCrumb('Стоп-лист', true),
                },
                children: [
                  {
                    index: true,
                    element: <ShopsPage />,
                    handle: {
                      label: 'Стоп-лист',
                      roles: roleSet.superAdmin,
                    },
                  },
                  {
                    path: `:id`,
                    element: (
                      <SuspenseContainer>
                        <ShopPage />
                      </SuspenseContainer>
                    ),
                    async lazy() {
                      const { shopLoader } = await import(
                        'src/pages/management/ShopPage/shopLoader'
                      );
                      return {
                        loader: shopLoader,
                      };
                    },
                  },
                ],
              },
            ],
          },
          {
            path: `zones`,
            handle: {
              crumb: getCrumb('Зоны доставки', true),
            },
            children: [
              {
                index: true,
                element: <ZonesPage />,
                handle: {
                  label: 'Зоны доставки',
                  roles: roleSet.adminToSuperAdmin,
                },
              },
            ],
          },
          {
            path: `streets`,
            element: <StreetsPageOLD />,
            handle: {
              label: 'Улицы',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `mango`,
            element: <MangoPage />,
            handle: {
              label: 'Манго',
              roles: roleSet.adminToSuperAdmin,
            },
          },
          {
            path: `settings`,
            element: <SettingsPage />,
            handle: { label: 'Настройки', roles: roleSet.adminToSuperAdmin },
            async lazy() {
              const { settingsLoader } = await import(
                'src/pages/management/SettingsPage/settingsLoader'
              );
              return {
                loader: settingsLoader,
              };
            },
          },
          {
            path: `entrepreneurs`,
            handle: {
              crumb: getCrumb('Юридические лица', true),
            },
            children: [
              {
                index: true,
                element: <EntrepreneursPage />,
                handle: {
                  label: 'Юридические лица',
                  roles: roleSet.adminToSuperAdmin,
                },
              },
              {
                path: `:id`,
                element: (
                  <SuspenseContainer>
                    <EntrepreneurPage />
                  </SuspenseContainer>
                ),
                async lazy() {
                  const { entrepreneurLoader } = await import(
                    'src/pages/management/EntrepreneurPage/entrepreneurLoader'
                  );
                  return {
                    loader: entrepreneurLoader,
                  };
                },
              },
            ],
          },
          {
            path: `sync`,
            element: <SyncPage />,
            handle: {
              label: 'Синхронизация',
              roles: roleSet.adminToSuperAdmin,
            },
            children: syncTabs,
          },
        ],
      },
    ],
  },
];
